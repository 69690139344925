import React from "react"
import Helmet from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import GridFilters from "../../components/grid"

const TourTeamsCampsPage = ({ data }) => {
  const page = data.wagtail.page
  let _ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    }
  })

  return (
    <>
      <Layout
        isContent={true}
        subMenu={_ancestor.children}
        button={_ancestor.button}
        parent={"athletes"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/athletes">Athletes</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="hero-heading">{page.title}</h1>
                <p>{page.descriptionSimple}</p>
                {page.feedImage && (
                  <div className="clib-area">
                    <div className="club-img full-width-image">
                      <img src={page.feedImage.src} alt="swim" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <GridFilters data={page} />
        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 0 && (
            <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
          )}
      </Layout>
      <Helmet>
        <script src={withPrefix("scripts/custom.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}
export const query = graphql`
  {
    wagtail {
      page(slug: "tour-teams") {
        ... on AthletePage {
          descriptionSimple
          id
          body
          feedImage {
            src
            height
            width
          }
          slug
          children {
            ... on EventPage {
              clinicname
              clinicDate
              clubname
              location
              eventname
              gender
              eventdescription
              initialdate
              enddate
              locationname
              locationaddress
              athletetype
              slug
              show
              description
              live
              isProtected
              ancestors(order: "-depth", limit: 1) {
                title
                depth
              }
            }
          }
          ancestors {
            ... on AthletesPage {
              children {
                title
                slug
                ... on AthletePage {
                  live
                  isProtected
                }
                ... on EventPage {
                  live
                  isProtected
                }
                ... on SimplePage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default TourTeamsCampsPage
